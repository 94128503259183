import { useEffect, useState } from "react";
import ElberegnerService from "../services/ElberegnerService.ts";
import { connect } from "react-redux";
import { setNetCompany } from "../store/actions/livedata";
import Dropdown from "./Dropdown";

const ChooseNetcompany = ({
  netCompany,
  setNetCompany,
  addressIsSelected,
  showCharges,
}) => {
  const [fetching, setFetching] = useState(false);
  const [netcompanies, setNetcompanies] = useState([]); // eslint-disable-line no-unused-vars
  const [showList, setShowList] = useState(false); // eslint-disable-line no-unused-vars

  useEffect(() => {
    const fetchNetcompanies = async () => {
      if (fetching) return;
      await setFetching(true);
      await new ElberegnerService()
        .fetchNetCompanies()
        .then((res) => setNetcompanies(res))
        .finally(() => {
          console.log("done fetching", fetching);
          setFetching(false);
        });
    };

    if (netcompanies.length === 0 && !fetching) {
      fetchNetcompanies();
    }
  }, [netcompanies, fetching]);

  if (!showCharges) return null;

  return (
    <>
      <Dropdown
        loading={fetching}
        label={"Vælg netselskab:"}
        disabled={addressIsSelected || !showCharges}
        value={netCompany?.name || "Ikke valgt"}
        onSelect={(value) => {
          setNetCompany(value);
        }}
        data={netcompanies.map((netcompany) => ({
          value: netcompany,
          label: netcompany.name,
        }))}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    netCompany: state.livedata.netCompany,
    showCharges: state.preferences.showCharges,
    addressIsSelected: state.address.item !== null,
  };
};
export default connect(mapStateToProps, { setNetCompany })(ChooseNetcompany);
