export interface ListItem {
  value: any;
  label: string;
}

interface Props {
  onClick: (value: any) => void;
  items: ListItem[];
}

const DropdownList = ({ onClick, items }: Props) => {
  return (
    <div
      className={
        "absolute top-8 max-h-[250px] overflow-y-scroll bg-white rounded shadow-xl right-0 z-20 max-w-lg w-full"
      }
    >
      <ul>
        {items.map((item, index) => (
          <li key={index}>
            <button
              className={
                "text-base py-1.5 px-2 border-b border-gray-300 sm:text-sm text-black flex w-full space-x-1 items-center hover:bg-green-600 hover:text-white transition-all"
              }
              onClick={() => onClick(item.value)}
            >
              {item.label}
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default DropdownList;
