import { CLEAR_ADDRESS, SET_ADDRESS, SET_ADDRESS_LOADING } from "../types";

const defaultState = {
  item: localStorage.getItem("address")
    ? JSON.parse(localStorage.getItem("address"))
    : null,
  loading: false,
};

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case SET_ADDRESS:
      localStorage.setItem("address", JSON.stringify(action.payload));
      return {
        ...state,
        item: action.payload,
        loading: false,
      };
    case CLEAR_ADDRESS:
      localStorage.removeItem("address");
      return {
        ...state,
        item: null,
        loading: false,
      };
    case SET_ADDRESS_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    default:
      return state;
  }
}
