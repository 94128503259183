import useSuppliers, { Supplier } from "../hooks/useSuppliers";
import Dropdown from "./Dropdown";
import { useEffect } from "react";
import { connect, MapStateToProps } from "react-redux";
import { Deal } from "../types";
import { setSupplierDeal } from "../store/actions/livedata";

interface Props {
  currentSupplier: Supplier | null;
  currentSupplierDeal: Deal | null;
  setSupplierDeal: (supplier: Deal) => void;
}

const ChooseSupplierDeal = ({
  currentSupplier,
  currentSupplierDeal,
  setSupplierDeal,
}: Props) => {
  const { fetchSupplierDeals, loading, deals } = useSuppliers();

  useEffect(() => {
    if (currentSupplier?.id) {
      fetchSupplierDeals(currentSupplier.id);
    }
  }, [currentSupplier]);

  useEffect(() => {
    if (deals.length === 1) {
      setSupplierDeal(deals[0]);
    }
  }, [deals]);

  return (
    <Dropdown
      loading={loading}
      disabled={deals.length === 1}
      label={"Vælg elaftale:"}
      value={currentSupplierDeal?.name || "Ikke valgt"}
      onSelect={(value) => {
        setSupplierDeal(deals.find((s) => s.id === value) as Deal);
      }}
      data={deals.map((deal) => ({
        value: deal.id,
        label: deal.name,
      }))}
    />
  );
};

const mapStateToProps: MapStateToProps<any, any, any> = (state) => {
  return {
    currentSupplier: state.livedata.supplier,
    currentSupplierDeal: state.livedata.supplierDeal,
  };
};

export default connect(mapStateToProps, { setSupplierDeal })(
  ChooseSupplierDeal
);
