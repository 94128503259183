import { HomeIcon, LightBulbIcon } from "@heroicons/react/24/outline";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import ElberegnerService from "./../services/ElberegnerService.ts";
import { connect } from "react-redux";
import { liveDataIsLoading, setNetCompany } from "../store/actions/livedata";
import { changeLocation } from "../store/actions/preferences";
import Spinner from "./icons/Spinner";
import { clearAddress, setAddress, setLoading } from "../store/actions/address";
import Button from "./Button";
import useAddressSuggestions from "../hooks/useAddressSuggestions.ts";

const AddressForm = ({
  changeLocation,
  setNetCompany,
  netCompany,
  selectedAddress,
  setAddress,
  clearAddress,
}) => {
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    // eslint-disable-next-line no-empty-pattern
    formState: {},
  } = useForm();

  const { suggestions, loading, getSuggestions, setSuggestions } =
    useAddressSuggestions();
  const [error, setError] = useState(null);

  const onSubmit = async (data) => {};

  const reset = () => {
    clearAddress();
    setSuggestions([]);
    setNetCompany([]);
    setValue("address", "");
  };

  // computed
  const hasWrittenAddress = watch("address") && watch("address").length > 3;

  const watchedAddress = watch("address");

  useEffect(() => {
    if (hasWrittenAddress) {
      setError(null);
      getSuggestions(watchedAddress);
    } else {
      setSuggestions([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchedAddress, hasWrittenAddress]);

  const setLocation = () => {
    if (parseInt(selectedAddress.zip_code) > 5000) {
      changeLocation("DK1");
    } else {
      changeLocation("DK2");
    }
  };

  useEffect(() => {
    setError(null);
    if (selectedAddress) {
      setLocation();
      setLoading(true);
      new ElberegnerService()
        .lookupNetCompany(selectedAddress.suggestion)
        .then((res) => {
          setNetCompany(res.data);
        })
        .catch((err) => {
          // get error message

          setError("Vi kunne ikke finde dit netselskab. Prøv igen.");
        })
        .finally(() => {
          setLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAddress]);

  return (
    <div
      className={
        "flex flex-wrap items-center mb-12 bg-green-300 py-4 px-2 sm:p-6 rounded w-full relative"
      }
    >
      {process.env.REACT_APP_COMPARE_LINK && (
        <div className={"justify-end w-full px-4 flex md:hidden"}>
          <Button
            href={"https://elberegner.dk/elselskaber/?dasite=elberegnerGraf"}
            target={"_blank"}
            text={"Sammenlign elselskaber her"}
          />
        </div>
      )}
      <div
        className={
          "absolute -bottom-[40px] left-1/2 -translate-x-1/2 border-t-[20px] border-b-[20px] border-b-transparent border-x-transparent border-green-300 border-x-[20px]"
        }
      ></div>
      <div className={"w-full sm:w-1/2 p-4"}>
        <div className={"sm:flex"}>
          <div
            className={
              "w-8 h-8 mb-4 sm:mb-0 shrink-0 rounded-full flex items-center justify-center bg-yellow-500 mr-4"
            }
          >
            <LightBulbIcon
              className="h-5 w-5 text-green-600"
              aria-hidden="true"
            />
          </div>
          <div>
            <h3 className={"font-medium"}>Få det fulde indblik</h3>
            <h2 className={"text-2xl text-green-600 font-semibold"}>
              Indtast din adresse for at se priserne hos dit netselskab
            </h2>
          </div>
        </div>
      </div>
      {!selectedAddress && (
        <form
          onSubmit={handleSubmit(onSubmit)}
          className={"w-full md:w-1/2 lg:w-1/2 p-4"}
        >
          {process.env.REACT_APP_COMPARE_LINK && (
            <div className={"justify-end hidden md:flex"}>
              <Button
                href={
                  "https://elberegner.dk/elselskaber/?dasite=elberegnerGraf"
                }
                target={"_blank"}
                text={"Sammenlign elselskaber her"}
              />
            </div>
          )}
          <div className={"relative max-w-md"}>
            <label
              htmlFor="address"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Indtast din adresse
            </label>
            <div className={"flex items-end"}>
              <div className="relative mt-2 rounded-md shadow-sm shrink-0 w-full">
                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                  {!loading && (
                    <HomeIcon
                      className="h-5 w-5 text-green-600"
                      aria-hidden="true"
                    />
                  )}
                  {loading && <Spinner />}
                </div>
                <input
                  autoComplete={"off"}
                  id={"address"}
                  {...register("address", { required: true })}
                  className="block w-full rounded-lg border-0 py-2 pl-10 text-gray-900 ring-1 ring-inset ring-green-500 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:outline-0 focus:shadow-none focus:ring-green-500"
                  placeholder="Indtast din adresse"
                />
              </div>
            </div>
            <div
              className={
                "max-h-[200px] overflow-y-scroll absolute top-30 bg-white shadow-2xl w-full"
              }
            >
              <ul>
                {suggestions.length > 0 &&
                  suggestions.map((suggestion) => {
                    return (
                      <li
                        className={"border-b border-gray-300"}
                        key={suggestion.address_id}
                      >
                        <button
                          className={
                            "px-2 py-1 hover:bg-green-600 hover:text-white w-full text-left"
                          }
                          onClick={() => {
                            setAddress(suggestion);
                          }}
                        >
                          {suggestion.suggestion}
                        </button>
                      </li>
                    );
                  })}
                {hasWrittenAddress && !loading && suggestions.length === 0 && (
                  <li
                    className={
                      "border-b border-gray-300 px-2 py-1 text-red-600"
                    }
                  >
                    Ingen resultater...
                  </li>
                )}
              </ul>
            </div>
          </div>
        </form>
      )}
      {selectedAddress && (
        <div className={"p-4"}>
          <p>
            <strong className={"font-semibold"}>Adresse:</strong>{" "}
            {selectedAddress.suggestion} -{" "}
            <button className={"text-green-500 underline"} onClick={reset}>
              Skift
            </button>
          </p>
          <p>
            <strong className={"font-semibold"}>Netselskab:</strong>{" "}
            {loading && <Spinner />}
            {error && <span className={"text-red-500"}>{error}</span>}
            {netCompany && netCompany.name}
          </p>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  netCompany: state.livedata.netCompany,
  selectedAddress: state.address.item,
  loading: state.address.loading,
});

export default connect(mapStateToProps, {
  liveDataIsLoading,
  setAddress,
  setLoading,
  clearAddress,
  changeLocation,
  setNetCompany,
})(AddressForm);
