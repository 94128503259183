import { useEffect } from "react";
import useSuppliers from "../hooks/useSuppliers";
import { connect, MapStateToProps } from "react-redux";
import { clearSupplier, setSupplier } from "../store/actions/livedata";
import Dropdown from "./Dropdown";
import { Provider } from "../types";

interface Props {
  currentSupplier: Provider | null;
  currentSupplierDeal: any;
  setSupplier: (supplier: Provider) => void;
  clearSupplier: () => void;
}

const ChooseSupplier = ({
  currentSupplier,
  setSupplier,
  clearSupplier,
}: Props) => {
  const { suppliers, fetchSuppliers, loading } = useSuppliers();

  useEffect(() => {
    if (suppliers.length === 0) {
      fetchSuppliers();
    }
  }, [fetchSuppliers, suppliers]);

  return (
    <>
      <Dropdown
        onRemove={clearSupplier}
        loading={loading}
        showClear={Object.keys(currentSupplier as object).length > 0}
        label={"Vælg elselskab:"}
        value={currentSupplier?.name || "Ikke valgt"}
        onSelect={(value) => {
          setSupplier(suppliers.find((s) => s.id === value) as Provider);
        }}
        data={suppliers.map((supplier) => ({
          value: supplier.id,
          label: supplier.name,
        }))}
      />
    </>
  );
};

const mapStateToProps: MapStateToProps<any, any, any> = (state) => {
  return {
    currentSupplier: state.livedata.supplier,
  };
};

export default connect(mapStateToProps, { setSupplier, clearSupplier })(
  ChooseSupplier
);
