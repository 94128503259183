import { useState } from "react";
import providerRepo from "../services/ProviderServices";
import { Deal, Provider } from "../types";

export interface Supplier {
  id: number;
  name: string;
  created_at: string;
  updated_at: string;
}

const useSuppliers = () => {
  const [suppliers, setSuppliers] = useState<Provider[]>([]);
  const [deals, setDeals] = useState<Deal[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const fetchSuppliers = async () => {
    setLoading(true);
    await providerRepo()
      .fetch()
      .then((data) => {
        setSuppliers(data);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchSupplierDeals = async (id: number) => {
    setLoading(true);
    await providerRepo()
      .dealsByProvider(id)
      .then((data) => {
        setDeals(data);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return {
    suppliers,
    deals,
    fetchSuppliers,
    fetchSupplierDeals,
    loading,
  };
};

export default useSuppliers;
