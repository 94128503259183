import {
  CLEAR_SUPPLIER,
  SET_LIVE_DATA,
  SET_LIVE_DATA_LOADING,
  SET_MAX_PRICE,
  SET_NET_COMPANY,
  SET_SUPPLIER,
  SET_SUPPLIER_DEAL,
  SET_TOMORROW_HAS_DATA,
} from "../types";

const defaultState = {
  items: [],
  loading: false,
  tomorrowHasData: false,
  netCompany: {},
  supplier: localStorage.getItem("supplier")
    ? JSON.parse(localStorage.getItem("supplier"))
    : {},
  supplierDeal: localStorage.getItem("supplierDeal")
    ? JSON.parse(localStorage.getItem("supplierDeal"))
    : {},
  maxPrice: 5,
};

export default function livedata(state = defaultState, action) {
  switch (action.type) {
    case SET_LIVE_DATA:
      return {
        ...state,
        items: action.payload,
      };
    case SET_LIVE_DATA_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case SET_TOMORROW_HAS_DATA:
      return {
        ...state,
        tomorrowHasData: action.payload,
      };
    case SET_MAX_PRICE:
      return {
        ...state,
        maxPrice: action.payload,
      };
    case SET_NET_COMPANY:
      return {
        ...state,
        netCompany: action.payload,
      };
    case SET_SUPPLIER:
      localStorage.setItem("supplier", JSON.stringify(action.payload));
      return {
        ...state,
        supplier: action.payload,
        supplierDeal: {},
      };
    case SET_SUPPLIER_DEAL:
      localStorage.setItem("supplierDeal", JSON.stringify(action.payload));
      return {
        ...state,
        supplierDeal: action.payload,
      };
    case CLEAR_SUPPLIER:
      localStorage.removeItem("supplier");
      localStorage.removeItem("supplierDeal");
      return {
        ...state,
        supplier: {},
        supplierDeal: {},
      };

    default:
      return state;
  }
}
