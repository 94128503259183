import Spinner from "./icons/Spinner";
import { ChevronUpDownIcon } from "@heroicons/react/24/solid";
import DropdownList, { ListItem } from "./DropdownList";
import { useState } from "react";

interface Props {
  loading: boolean;
  value?: any;
  label: string;
  disabled?: boolean;
  data: ListItem[];
  onRemove?: () => void;
  showClear?: boolean;
  onSelect: (value: any) => void;
}

const Dropdown = ({
  loading,
  value,
  label,
  data,
  onSelect,
  onRemove,
  showClear = false,
  disabled = false,
}: Props) => {
  const [showList, setShowList] = useState<boolean>(false);
  return (
    <div className={"flex items-center justify-center sm:justify-end relative"}>
      <p className={"text-base sm:text-sm text-gray-700 mr-2"}>{label}</p>
      {loading && <Spinner />}
      {!loading && (
        <div className={""}>
          <button
            disabled={disabled}
            className={
              "text-base sm:text-sm font-semibold text-black flex space-x-1 items-center disabled:opacity-50 transition-all"
            }
            onClick={() => setShowList(!showList)}
          >
            <ChevronUpDownIcon className={"w-4 h-4"} stroke={"2"} />
            {value || "Vælg"}
          </button>
          {showList && (
            <DropdownList
              items={data}
              onClick={(value) => {
                onSelect(value);
                setShowList(false);
              }}
            />
          )}
        </div>
      )}
      {onRemove && showClear && (
        <button
          onClick={onRemove}
          className={
            "ml-2 w-4 h-4 bg-red-600 rounded-full flex items-center justify-center text-white"
          }
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={3}
            stroke="currentColor"
            className="w-3 h-3"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 18 18 6M6 6l12 12"
            />
          </svg>
        </button>
      )}
    </div>
  );
};

export default Dropdown;
