import ElberegnerService from "../services/ElberegnerService";
import { useState } from "react";

const useAddressSuggestions = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [suggestions, setSuggestions] = useState<any[]>([]);
  const getSuggestions = async (address: string) => {
    setLoading(true);
    await new ElberegnerService()
      .getAddressSuggestions(address)
      .then((res) => {
        setSuggestions(res.data);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return {
    loading,
    suggestions,
    setSuggestions,
    getSuggestions,
  };
};

export default useAddressSuggestions;
