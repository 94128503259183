import {
  CLEAR_SUPPLIER,
  SET_LIVE_DATA,
  SET_LIVE_DATA_LOADING,
  SET_MAX_PRICE,
  SET_NET_COMPANY,
  SET_SUPPLIER,
  SET_SUPPLIER_DEAL,
  SET_TOMORROW_HAS_DATA,
} from "../types";

export const setLiveData = (items) => {
  return {
    type: SET_LIVE_DATA,
    payload: items,
  };
};

export const liveDataIsLoading = (loading) => {
  return {
    type: SET_LIVE_DATA_LOADING,
    payload: loading,
  };
};

export const tomorrowHasData = (bool) => {
  return {
    type: SET_TOMORROW_HAS_DATA,
    payload: bool,
  };
};

export const setMaxPrice = (maxPrice) => {
  return {
    type: SET_MAX_PRICE,
    payload: maxPrice,
  };
};

export const setNetCompany = (company) => {
  return {
    type: SET_NET_COMPANY,
    payload: company,
  };
};

export const setSupplier = (supplier) => {
  return {
    type: SET_SUPPLIER,
    payload: supplier,
  };
};

export const setSupplierDeal = (deal) => {
  return {
    type: SET_SUPPLIER_DEAL,
    payload: deal,
  };
};

export const clearSupplier = () => {
  return {
    type: CLEAR_SUPPLIER,
    payload: null,
  };
};

export const getNetCompanyCharges = (netCompany) => {
  if (!netCompany?.name) return 0;

  // get the sum of all the avr_price
  let sum = 0;

  sum += netCompany?.charges.subscriptions?.reduce((acc, curr) => {
    return acc + curr.price_ex_vat;
  }, 0);

  return sum;
};

export const getNetCompanyCTime = (netCompany, index) => {
  if (!netCompany?.name) return 0;

  let sum = 0;

  // get index of netCompany?.charges.tariffs and return the sum of the avr_price
  sum += netCompany?.charges.tariffs?.reduce((acc, curr) => {
    return acc + curr.prices_ex_vat[index];
  }, 0);

  return sum;
};
