export const SET_LIVE_DATA = "SET_LIVE_DATA";
export const SET_LIVE_DATA_LOADING = "SET_LIVE_DATA_LOADING";
export const SET_TOMORROW_HAS_DATA = "SET_TOMORROW_HAS_DATA";
export const SET_MAX_PRICE = "SET_MAX_PRICE";

export const SET_NET_COMPANY = "SET_NET_COMPANY";

// preferences
export const SET_LOCATION = "SET_LOCATION";
export const SET_DATE = "SET_DATE";
export const TOGGLE_CHARGES = "TOGGLE_CHARGES";
export const SET_CHARGES = "SET_CHARGES";

// address
export const SET_ADDRESS = "SET_ADDRESS";
export const SET_ADDRESS_LOADING = "SET_ADDRESS_LOADING";
export const CLEAR_ADDRESS = "CLEAR_ADDRESS";

// supplier
export const SET_SUPPLIER = "SET_SUPPLIER";
export const SET_SUPPLIER_DEAL = "SET_SUPPLIER_DEAL";
export const CLEAR_SUPPLIER = "CLEAR_SUPPLIER";
