import ElberegnerService from "./ElberegnerService";

const providerRepo = () => {
  const fetch = () => {
    return new ElberegnerService().fetchProviders();
  };

  const dealsByProvider = (providerId: number) => {
    return new ElberegnerService().providerDeals(providerId);
  };

  return {
    fetch,
    dealsByProvider,
  };
};

export default providerRepo;
